import { render, staticRenderFns } from "./Coordenador_Visualizar_Unidades.vue?vue&type=template&id=b299d3bc&scoped=true&"
import script from "./Coordenador_Visualizar_Unidades.vue?vue&type=script&lang=js&"
export * from "./Coordenador_Visualizar_Unidades.vue?vue&type=script&lang=js&"
import style0 from "./Coordenador_Visualizar_Unidades.vue?vue&type=style&index=0&id=b299d3bc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b299d3bc",
  null
  
)

export default component.exports