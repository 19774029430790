<template>
  <div class="here bg-edf0f5 py-5">
    <div
      class="container bg-fff in-here border-default shadow-sm border-default shadow-sm text-left p-4"
    >
      <button
        class="btn-style-default-less-2 color-fff bg-0e5caf mr-3 text-uppercase f-w-700"
        @click="emitParaMudarCP('CoordenadorVisualizarAlunos')"
      >
        voltar para turma
      </button>
      <h2 class="title-dados pb-2 mt-5 mb-3">Progresso Indivídual</h2>
      <div class="d-flex c-center mb-2 aluno pl-4 position-relative">
        <div id="nivelIconClass" class="position-absolute">
          <div class="d-flex align-items-center justify-content-center">
            <div
              class="nivelIconClass"
              :style="`background-image: url(${require('@/assets/aluno/peao.png')}); border: 2px solid #fcaf66`"
            ></div>
          </div>
          <div>
            <small class="mr-1">Nível Peão</small>
          </div>
        </div>
        <div
          id="alunoPhotoPerfil"
          :style="`background-image: url(${require('@/assets/aluno/perfilDefault.png')})`"
        ></div>
        <div class="ml-3">Aluno Skaki</div>
      </div>
      <div class="row pb-4 px-4">
        <div class="col-md-12 mx-auto h2 row text-left border-bottom mt-3 pb-2 px-0">
          <div class="col-md-6 pl-0">
            <h2>Tema</h2>
          </div>
          <div class="col-md-2">
            <h2>Tentativas</h2>
          </div>
          <div class="col-md-4 pl-0">
            <h2>Conclusão de Tema</h2>
          </div>
        </div>
        <div
          class="col-md-12 border-default shadow-sm border-default shadow-sm the-border py-3 pr-0 my-2"
          v-for="(item, index) in aulas"
          :key="index"
        >
          <div class="row">
            <div class="col-md-6 pl-0">
              <div class="a-concluir row mx-0 px-3">
                <div
                  class="col-md-1 d-flex align-items-center justify-content-center arrow"
                >
                  <img
                    :src="
                      require(`../../assets/aluno/Icon-material-label-outline${carregaArrow(
                        item.qtdeAula,
                        item.qtdeFeitas
                      )}.png`)
                    "
                    alt="Icon-material-label-outline.png"
                  />
                </div>
                <div
                  :class="`col-md-11 row mx-0 bg-dfdfdf shape-show-status-aulas position-relative ${
                    item.qtdeFeitas > 0 && item.qtdeFeitas < item.qtdeAula
                      ? 'bg-0e5caf'
                      : item.qtdeFeitas == 0
                      ? 'bg-e4e5e8'
                      : 'bg-42cc7e'
                  }`"
                >
                  <small
                    :class="`position-absolute shape-show-status-aulas-idaula ${
                      item.qtdeFeitas > 0 && item.qtdeFeitas < item.qtdeAula
                        ? 'color-fff'
                        : item.qtdeFeitas == 0
                        ? 'color-000'
                        : 'color-fff'
                    }`"
                    >{{ `Cód. ${item.codigo}` }}</small
                  >
                  <div class="col-md-7 pl-0 d-flex c-center">
                    <h3
                      :class="`text-left title-concluido title ${
                        item.qtdeFeitas > 0 && item.qtdeFeitas < item.qtdeAula
                          ? 'color-fff'
                          : item.qtdeFeitas == 0
                          ? 'color-000'
                          : 'color-fff'
                      }`"
                    >
                      {{ item.label }}
                    </h3>
                  </div>
                  <div
                    :class="`border-radius-3 col-md-5 d-flex align-items-center justify-content-center px-0  ${
                      item.qtdeFeitas > 0 && item.qtdeFeitas < item.qtdeAula
                        ? 'bg-fff'
                        : item.qtdeFeitas == 0
                        ? 'bg-fff'
                        : 'bg-fedd0a'
                    }`"
                  >
                    <span
                      :class="`mr-2 f-w-700 ${
                        item.qtdeFeitas > 0 && item.qtdeFeitas < item.qtdeAula
                          ? 'color-0e5caf'
                          : item.qtdeFeitas == 0
                          ? 'color-000'
                          : 'color-000'
                      }`"
                      >{{ `${item.qtdeFeitas}/${item.qtdeAula}` }}
                      <span>{{
                        item.qtdeFeitas > 0 && item.qtdeFeitas < item.qtdeAula
                          ? "Em progresso"
                          : item.qtdeFeitas == 0
                          ? "Não iniciado"
                          : "Completo!"
                      }}</span>
                    </span>
                    <img
                      :src="
                        item.qtdeFeitas > 0 && item.qtdeFeitas < item.qtdeAula
                          ? iconeContinuar
                          : item.qtdeFeitas == 0
                          ? iconeAConcluir
                          : iconeCompletou
                      "
                      alt="iconAConcluir.png"
                      class="ml-2"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 row pr-0">
              <div class="col-md-4 d-flex c-center">
                <h3 v-if="item.qtdeFeitas != 0" class="text-uppercase">
                  {{ `${item.tentativas}/3` }}
                </h3>
                <h3 v-else class="text-uppercase">- -</h3>
              </div>
              <div class="col-md-3 d-flex c-center">
                <h3 v-if="item.conclusao_tema != 0">
                  {{
                    `${item.conclusao_tema} ${item.conclusao_tema > 1 ? "vezes" : "vez"}`
                  }}
                </h3>
                <h3 v-else>- -</h3>
              </div>
              <div
                :class="`col-md-5 d-flex justify-content-end pr-0 ${
                  item.qtdeFeitas == 0 ? 'opacity-medium' : null
                }`"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <button
                    :disabled="item.qtdeFeitas == 0"
                    class="btn-style-default-less-2 color-fff bg-222541 mr-3"
                    @click="emitParaMudarCP('alunoMeusTemasExercicioComponent')"
                  >
                    <i class="fa fa-eye" aria-hidden="true"> </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iconeContinuar: require("@/assets/aluno/iconContinuarBlue.png"),
      iconeCompletou: require("@/assets/aluno/iconCompletouBlack.png"),
      iconeAConcluir: require("@/assets/aluno/iconAConcluir.png"),
      aulas: [
        {
          codigo: "0000",
          label: "Aprendendo Padrões: Parte 01",
          qtdeAula: 6,
          qtdeFeitas: 6,
          tentativas: 2,
          conclusao_tema: 1,
        },
        {
          codigo: "0001",
          label: "Aprendendo Padrões: Parte 02",
          qtdeAula: 6,
          qtdeFeitas: 1,
          tentativas: 1,
          conclusao_tema: 0,
        },
        {
          codigo: "0002",
          label: "Aprendendo Padrões: Parte 03",
          qtdeAula: 6,
          qtdeFeitas: 0,
          tentativas: 0,
          conclusao_tema: 0,
        },
        {
          codigo: "0002",
          label: "Aprendendo Padrões: Parte 03",
          qtdeAula: 6,
          qtdeFeitas: 0,
          tentativas: 0,
          conclusao_tema: 0,
        },
      ],
    };
  },
  methods: {
    emitParaMudarCP(val) {
      this.$emit("mudaComponente", val);
    },
    carregaArrow(fazer, feitas) {
      return fazer == feitas ? "-verde" : feitas < fazer && feitas != 0 ? "" : "-cinza";
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 14px;
}

h3,
button {
  font-size: 12px;
  font-weight: 400;
}

.border-bottom {
  border-bottom: 1px solid #e4e5e8;
}

.aluno {
  border: 2px solid #42cc7e;
  background-color: #edf0f5;
  width: 275px;
  border-radius: 5px;
  padding: 15px;
}

#nivelIconClass {
  right: 5px;
  top: 10px;
}

small {
  font-size: 9px;
}

#nivelIconClass > div:nth-child(2) {
  margin-top: -5px;
}

.nivelIconClass {
  border: none !important;
}

.title-concluido {
  font-size: 14px !important;
}
</style>
