<template>
<div id="Coordenador_Progresso_Turmas" class="pl-1">
    <transition-group name="fade">
        <div v-if="show" key="componentes-em-coordenadoria">
            <component :is="componentEmQuestao" @mudaComponente="changeCP" />
        </div>
    </transition-group>
</div>
</template>

<script>
import CoordenadorPesquisarUnidades from '../components/coordenador/Coordenador_Pesquisar_Unidades'
import CoordenadorVisualizarUnidades from '../components/coordenador/Coordenador_Visualizar_Unidades'
import CoordenadorVisualizarAlunos from '../components/coordenador/Coordenador_Visualizar_Alunos'
import CoordenadorVisualizarEvolucaoAluno from '../components/coordenador/Coordenador_Visualizar_Evolucao_Aluno'
import alunoMeusTemasExercicioComponent from '../components/aluno/alunoMeusTemasExercicioComponent'

export default {
    data() {
        return {
            componentEmQuestao: CoordenadorPesquisarUnidades,
            show: false
        }
    },
    components: {
        CoordenadorPesquisarUnidades,
        CoordenadorVisualizarUnidades,
        CoordenadorVisualizarAlunos,
        CoordenadorVisualizarEvolucaoAluno,
        alunoMeusTemasExercicioComponent
    },
    mounted() {
        this.show = true
    },
    methods: {
        changeCP(nomeDoComponente) {
            this.show = false
            this.componentEmQuestao = nomeDoComponente
            setTimeout(() => {
                this.show = true
            }, 100);
        }
    },
}
</script>

<style scoped>

</style>
