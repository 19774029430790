import { render, staticRenderFns } from "./Coordenador_Pesquisar_Unidades.vue?vue&type=template&id=4389ac30&scoped=true&"
import script from "./Coordenador_Pesquisar_Unidades.vue?vue&type=script&lang=js&"
export * from "./Coordenador_Pesquisar_Unidades.vue?vue&type=script&lang=js&"
import style0 from "./Coordenador_Pesquisar_Unidades.vue?vue&type=style&index=0&id=4389ac30&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4389ac30",
  null
  
)

export default component.exports