var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"here bg-edf0f5 py-5"},[_c('div',{staticClass:"container bg-fff in-here border-default shadow-sm border-default shadow-sm text-left p-4"},[_c('button',{staticClass:"btn-style-default-less-2 color-fff bg-0e5caf mr-3 text-uppercase f-w-700",on:{"click":function($event){return _vm.emitParaMudarCP('CoordenadorVisualizarAlunos')}}},[_vm._v(" voltar para turma ")]),_c('h2',{staticClass:"title-dados pb-2 mt-5 mb-3"},[_vm._v("Progresso Indivídual")]),_c('div',{staticClass:"d-flex c-center mb-2 aluno pl-4 position-relative"},[_c('div',{staticClass:"position-absolute",attrs:{"id":"nivelIconClass"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"nivelIconClass",style:(("background-image: url(" + (require('@/assets/aluno/peao.png')) + "); border: 2px solid #fcaf66"))})]),_vm._m(0)]),_c('div',{style:(("background-image: url(" + (require('@/assets/aluno/perfilDefault.png')) + ")")),attrs:{"id":"alunoPhotoPerfil"}}),_c('div',{staticClass:"ml-3"},[_vm._v("Aluno Skaki")])]),_c('div',{staticClass:"row pb-4 px-4"},[_vm._m(1),_vm._l((_vm.aulas),function(item,index){return _c('div',{key:index,staticClass:"col-md-12 border-default shadow-sm border-default shadow-sm the-border py-3 pr-0 my-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 pl-0"},[_c('div',{staticClass:"a-concluir row mx-0 px-3"},[_c('div',{staticClass:"col-md-1 d-flex align-items-center justify-content-center arrow"},[_c('img',{attrs:{"src":require(("../../assets/aluno/Icon-material-label-outline" + (_vm.carregaArrow(
                      item.qtdeAula,
                      item.qtdeFeitas
                    )) + ".png")),"alt":"Icon-material-label-outline.png"}})]),_c('div',{class:("col-md-11 row mx-0 bg-dfdfdf shape-show-status-aulas position-relative " + (item.qtdeFeitas > 0 && item.qtdeFeitas < item.qtdeAula
                    ? 'bg-0e5caf'
                    : item.qtdeFeitas == 0
                    ? 'bg-e4e5e8'
                    : 'bg-42cc7e'))},[_c('small',{class:("position-absolute shape-show-status-aulas-idaula " + (item.qtdeFeitas > 0 && item.qtdeFeitas < item.qtdeAula
                      ? 'color-fff'
                      : item.qtdeFeitas == 0
                      ? 'color-000'
                      : 'color-fff'))},[_vm._v(_vm._s(("Cód. " + (item.codigo))))]),_c('div',{staticClass:"col-md-7 pl-0 d-flex c-center"},[_c('h3',{class:("text-left title-concluido title " + (item.qtdeFeitas > 0 && item.qtdeFeitas < item.qtdeAula
                        ? 'color-fff'
                        : item.qtdeFeitas == 0
                        ? 'color-000'
                        : 'color-fff'))},[_vm._v(" "+_vm._s(item.label)+" ")])]),_c('div',{class:("border-radius-3 col-md-5 d-flex align-items-center justify-content-center px-0  " + (item.qtdeFeitas > 0 && item.qtdeFeitas < item.qtdeAula
                      ? 'bg-fff'
                      : item.qtdeFeitas == 0
                      ? 'bg-fff'
                      : 'bg-fedd0a'))},[_c('span',{class:("mr-2 f-w-700 " + (item.qtdeFeitas > 0 && item.qtdeFeitas < item.qtdeAula
                        ? 'color-0e5caf'
                        : item.qtdeFeitas == 0
                        ? 'color-000'
                        : 'color-000'))},[_vm._v(_vm._s(((item.qtdeFeitas) + "/" + (item.qtdeAula)))+" "),_c('span',[_vm._v(_vm._s(item.qtdeFeitas > 0 && item.qtdeFeitas < item.qtdeAula ? "Em progresso" : item.qtdeFeitas == 0 ? "Não iniciado" : "Completo!"))])]),_c('img',{staticClass:"ml-2",attrs:{"src":item.qtdeFeitas > 0 && item.qtdeFeitas < item.qtdeAula
                        ? _vm.iconeContinuar
                        : item.qtdeFeitas == 0
                        ? _vm.iconeAConcluir
                        : _vm.iconeCompletou,"alt":"iconAConcluir.png"}})])])])]),_c('div',{staticClass:"col-md-6 row pr-0"},[_c('div',{staticClass:"col-md-4 d-flex c-center"},[(item.qtdeFeitas != 0)?_c('h3',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(((item.tentativas) + "/3"))+" ")]):_c('h3',{staticClass:"text-uppercase"},[_vm._v("- -")])]),_c('div',{staticClass:"col-md-3 d-flex c-center"},[(item.conclusao_tema != 0)?_c('h3',[_vm._v(" "+_vm._s(((item.conclusao_tema) + " " + (item.conclusao_tema > 1 ? "vezes" : "vez")))+" ")]):_c('h3',[_vm._v("- -")])]),_c('div',{class:("col-md-5 d-flex justify-content-end pr-0 " + (item.qtdeFeitas == 0 ? 'opacity-medium' : null))},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('button',{staticClass:"btn-style-default-less-2 color-fff bg-222541 mr-3",attrs:{"disabled":item.qtdeFeitas == 0},on:{"click":function($event){return _vm.emitParaMudarCP('alunoMeusTemasExercicioComponent')}}},[_c('i',{staticClass:"fa fa-eye",attrs:{"aria-hidden":"true"}})])])])])])])})],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('small',{staticClass:"mr-1"},[_vm._v("Nível Peão")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 mx-auto h2 row text-left border-bottom mt-3 pb-2 px-0"},[_c('div',{staticClass:"col-md-6 pl-0"},[_c('h2',[_vm._v("Tema")])]),_c('div',{staticClass:"col-md-2"},[_c('h2',[_vm._v("Tentativas")])]),_c('div',{staticClass:"col-md-4 pl-0"},[_c('h2',[_vm._v("Conclusão de Tema")])])])}]

export { render, staticRenderFns }