<template>
  <div class="here bg-edf0f5 py-5">
    <div class="container bg-fff in-here shadow-sm">
      <div class="row pb-4">
        <div class="lupa col-md-11 mx-auto text-center d-flex my-4 px-0">
          <div class="position-relative">
            <img
              class="position-absolute"
              src="@/assets/icones/lupa.png"
              alt="lupa.png"
            />
            <input
              type="text"
              placeholder="Digite o nome ou o código para pesquisar "
              class="shadow-sm border-default shadow-sm"
            />
          </div>
          <!-- <button
            class="text-uppercase btn-style-default-less bg-222541 ml-3 color-fff shadow-sm"
          >
            aplicar
          </button> -->
        </div>
        <div class="col-md-11 mx-auto text-left px-0">
          <h2 class="title-dados pb-2">Unidades/Instituições Cadastradas</h2>
        </div>
        <div
          class="col-md-11 mx-auto h2 row text-left border-bottom mt-5 pb-2 px-0"
        >
          <div class="col-md-2 pl-0">
            <h2>Turmas</h2>
          </div>
          <div class="col-md-2">
            <h2>Número de Alunos</h2>
          </div>
          <div class="col-md-2 pl-0">
            <h2>Cadastrada em</h2>
          </div>
          <div class="col-md-4">
            <h2>Exercícios Gerais Realizados</h2>
          </div>
        </div>
        <div
          class="col-md-11 mx-auto h2 row text-left border-bottom py-3 px-0"
          v-for="(item, index) in unidades"
          :key="index"
        >
          <div class="col-md-2 d-flex c-center pl-0">
            <h3>{{ item.turma }}</h3>
          </div>
          <div class="col-md-2 d-flex c-center">
            <h3>{{ item.qtdeAlunos }}</h3>
          </div>
          <div class="col-md-2 d-flex c-center">
            <h3>{{ item.dtpublicacao }}</h3>
          </div>
          <div class="col-md-2 d-flex c-center">
            <h3 class="text-uppercase">
              {{ `${item.exercicios_realizados}% realizados` }}
            </h3>
          </div>
          <div class="col-md-4 d-flex justify-content-end pr-0">
            <button
              class="btn-style-default-less-2 color-fff bg-222541 mr-3"
              @click="emitParaMudarCP('CoordenadorVisualizarAlunos')"
            >
              <i class="fa fa-eye" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      unidades: [
        {
          turma: "001 - 5 SérieA",
          qtdeAlunos: 36,
          dtpublicacao: "27/09/2020",
          exercicios_realizados: 20,
        },
        {
          turma: "002 - 5 Série B",
          qtdeAlunos: 36,
          dtpublicacao: "27/09/2020",
          exercicios_realizados: 30,
        },
        {
          turma: "003 - 5 Série C",
          qtdeAlunos: 36,
          dtpublicacao: "27/09/2020",
          exercicios_realizados: 40,
        },
        {
          turma: "004 - 5 Série D",
          qtdeAlunos: 36,
          dtpublicacao: "27/09/2020",
          exercicios_realizados: 50,
        },
        {
          turma: "004 - 5 Série E",
          qtdeAlunos: 36,
          dtpublicacao: "27/09/2020",
          exercicios_realizados: 25,
        },
      ],
    };
  },
  methods: {
    emitParaMudarCP(val) {
      this.$emit("mudaComponente", val);
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 14px;
}

h3,
button {
  font-size: 12px;
  font-weight: 400;
}

input {
  height: 40px;
  border: 1px solid #e4e5e8;
}

.border-bottom {
  border-bottom: 1px solid #e4e5e8;
}

.lupa > div {
  width: 100%;
}

.lupa img {
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
</style>
