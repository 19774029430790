<template>
  <div id="CoordenadorPesquisarUnidades">
    <h2 class="mb-3 title main-title">
      Bem-vindo(a), {{verifyStatus($store.getters.getUserDatas.profile_id)}} {{ $store.getters.getUserDatas.name}}
    </h2>
    <div class="here bg-edf0f5 py-5">
      <div class="container bg-fff in-here shadow-sm">
        <div class="row pb-4">
          <div class="lupa col-md-11 mx-auto text-center d-flex my-4 px-0">
            <div class="position-relative">
              <img
                class="position-absolute opacity-more"
                src="@/assets/icones/lupa.png"
                alt="lupa.png"
              />
              <input
                type="text"
                placeholder="Digite o nome ou o código para pesquisar "
                class="border-default shadow-sm-2"
              />
            </div>
            <!-- <button
              class="text-uppercase btn-style-default-less bg-222541 ml-3 color-fff"
            >
              aplicar
            </button> -->
          </div>
          <div class="col-md-11 mx-auto text-left px-0 mt-2">
            <h2 class="title-dados pb-2">Unidades/Instituições Cadastradas</h2>
          </div>
          <div class="col-md-11 mx-auto h2 row text-left border-bottom mt-5 pb-2 px-0">
            <div :class="`col-md-1 pl-0`">
              <h2>Código</h2>
            </div>
            <div :class="classInQuestMd2">
              <h2>CNPJ</h2>
            </div>
            <div v-if="typeOfPerfilInQuest == 3" :class="classInQuestMd2">
              <h2>unidade</h2>
            </div>
            <div :class="classInQuestMd2">
              <h2>Cadastrada em</h2>
            </div>
          </div>
          <div
            class="col-md-11 mx-auto h2 row text-left border-bottom py-3 px-0"
            v-for="(item, index) in unidades"
            :key="index"
          >
            <div :class="`col-md-1 d-flex c-center pl-0`">
              <h3>{{ item.codigo }}</h3>
            </div>
            <div :class="`${classInQuestMd2} d-flex c-center`">
              <h3>{{ item.cnpj }}</h3>
            </div>
            <div
              v-if="typeOfPerfilInQuest == 3"
              :class="`${classInQuestMd2} d-flex c-center`"
            >
              <h3>{{ item.nome }}</h3>
            </div>
            <div :class="`${classInQuestMd2} d-flex c-center`">
              <h3>{{ item.dtpublicacao }}</h3>
            </div>
            <div class="col-md-5 d-flex justify-content-end pr-0">
              <!--btn-style-default-less-2-->
              <button
                class="color-fff mr-3 bg-000 btn-style-default-less"
                @click="emitParaMudarCP('CoordenadorVisualizarUnidades')"
              >
                <i class="fa fa-eye" aria-hidden="true"></i>
              </button>
              <!--btn-style-default-less-2-->
              <button
                class="color-fff mr-3 bg-d42338 btn-style-default-less d-none"
                @click="emitParaMudarCP('CoordenadorVisualizarUnidades')"
              >
                visualizar unidade
              </button>
              <!--<button class=" color-fff">editar/excluir unidade</button>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ParticleBtn from "vue-particle-effect-buttons";
export default {
  data() {
    return {
      btnOps: {
        type: "circle",
        easing: "easeInOutCubic",
        duration: 500,
        size: 1,
        particlesAmountCoefficient: 2,
        oscillationCoefficient: 20,
        direction: "top",
        color: function () {
          return Math.random() < 0.5 ? "#000000" : "#ffffff";
        },
        onComplete: () => {},
        onBegin: () => {},
        visible: false,
        animating: true,
      },
      typeOfPerfilInQuest: this.$route.params.typeOfPerfil == "professor" ? 3 : 2,
      classInQuestMd1:
        this.$route.params.typeOfPerfil == "professor" ? "col-md-1" : "col-md-2",
      classInQuestMd2:
        this.$route.params.typeOfPerfil == "professor" ? "col-md-2" : "col-md-3",
      classInQuestMd3:
        this.$route.params.typeOfPerfil == "professor" ? "col-md-3" : "col-md-2",
      unidades: [
        {
          nome: "Escola Estadual Dom Bosco",
          codigo: "001",
          cnpj: "000.000.000/0000",
          dtpublicacao: "27/09/2020",
        },
        {
          nome: "Escola Estadual Dom Bosco",
          codigo: "002",
          cnpj: "000.000.000/0000",
          dtpublicacao: "27/09/2020",
        },
        {
          nome: "Escola Estadual Dom Bosco",
          codigo: "003",
          cnpj: "000.000.000/0000",
          dtpublicacao: "27/09/2020",
        },
        {
          nome: "Escola Estadual Dom Bosco",
          codigo: "004",
          cnpj: "000.000.000/0000",
          dtpublicacao: "27/09/2020",
        },
        {
          nome: "Escola Estadual Dom Bosco",
          codigo: "004",
          cnpj: "000.000.000/0000",
          dtpublicacao: "27/09/2020",
        },
      ],
    };
  },
  components: {
    ParticleBtn,
  },
  mounted() {
    this.btnOps.visible = true;
    $(".particles-button").css({
      "background-color": "transparent",
    });
  },
  methods: {
    emitParaMudarCP(val) {
      this.$emit("mudaComponente", val);
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 14px;
}

h3,
button {
  font-size: 12px;
  font-weight: 400;
}

input {
  height: 40px;
  border: 1px solid #e4e5e8;
}

.border-bottom {
  border-bottom: 1px solid #e4e5e8;
}
</style>
